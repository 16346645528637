import React, { memo, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';

import styles from './SchoolPage.module.css';

import {
  dashboardPath,
  errorDialogMessage,
  placeholderLoading,
  schoolNoLicensesWarning,
  schoolOverAllocatedWarning,
  schoolUnpaidLicensesWarning,
} from '../../strings';

import { useAuth } from '../../context/Auth';
import logException from '../../services/logging/exception';
import Placeholder from '../../components/placeholder/Placeholder';
import FourOhFour from '../../components/fourOhFour/FourOhFour';
import { errorHasStatusCode } from '../../utils/error';

import {
  GetSchoolPage,
  GetSchoolPageVariables,
  GetSchoolPage_school_schoolMembers_account,
} from './types/GetSchoolPage';
import {
  SCHOOL_CLASS_MEMBER_ROLE,
  SCHOOL_MEMBER_ROLE,
} from '../../types/graphql-types';
import DropdownMenu, {
  MenuItem,
} from '../../components/dropdownMenu/DropdownMenu';
import MenuButton from '../../components/menuButton/MenuButton';
import Tabs from '../../components/atoms/tabs/Tabs';
import { RenameSchool, RenameSchoolVariables } from './types/RenameSchool';
import SchoolRoster from '../../components/schoolRoster/SchoolRoster';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import InputDialog from '../../components/inputDialog/InputDialog';
import TabsPageLayout from '../../components/layouts/tabsPageLayout/TabsPageLayout';
import SchoolLicenses from '../../components/schoolLicenses/SchoolLicenses';
import Tag from '../../components/tag/Tag';
import { WarningIcon } from '../../components/icons';
import {
  hasNoLicenses,
  hasUnpaidLicenses,
  isOverAllocated,
} from '../../utils/schoolWarnings';
import SchoolClasses from '../../components/schoolClasses/SchoolClasses';
import Dialog from '../../components/dialog/Dialog';
import PtProgress from '../../components/ptProgress/PtProgress';
import { LeaveSchool, LeaveSchoolVariables } from './types/LeaveSchool';
import { useTrackEvent } from '../../hooks/useTrackEvent';

function getAccountName(account: {
  firstName?: string | null;
  lastName?: string | null;
  username: string;
}) {
  if (account?.firstName && account?.lastName) {
    return `${account.firstName} ${account.lastName}`;
  }
  if (account?.firstName) {
    return account.firstName;
  }
  if (account?.lastName) {
    return account.lastName;
  }
  return account?.username || 'Unknown';
}

const GET_SCHOOL_PAGE = gql`
  query GetSchoolPage($schoolId: String!) {
    school(id: $schoolId) {
      __typename
      id
      name

      hasSufficientSeats
      numLicenses

      schoolClasses {
        __typename
        id
        name
        schoolClassMembers {
          __typename
          id
          role
          schoolMember {
            __typename
            id
            account {
              __typename
              id
              firstName
              lastName
              username
            }
          }
        }
        assignments {
          __typename
          id
        }
      }
      schoolContentAccess {
        __typename
        id
        realm {
          __typename
          id
          name
        }
        course {
          __typename
          id
          title
        }
        schoolMembers {
          __typename
          id
        }
        maxSeats
        numBuildingLicenses
      }
      schoolMembers {
        __typename
        id
        role
        passwordManaged
        account {
          __typename
          id
          firstName
          lastName
          username
          email
        }
        missingContentAccess {
          __typename
          id
          realm {
            __typename
            id
            name
          }
          course {
            __typename
            id
            title
          }
        }
      }
      licenses {
        __typename
        id
        valid
        status
        name
        description
        code
        currentPeriodEnd
        cancelAtPeriodEnd
        products {
          __typename
          id
          images
        }
        contentGrants {
          __typename
          realm {
            __typename
            id
            name
          }
          course {
            __typename
            id
            title
          }
          maxSeats
          numBuildingLicenses
        }
      }
    }
  }
`;

const RENAME_SCHOOL = gql`
  mutation RenameSchool($schoolId: String!, $name: String!) {
    updateSchool(id: $schoolId, name: $name) {
      __typename
      id
      name
    }
  }
`;

const LEAVE_SCHOOL = gql`
  mutation LeaveSchool($schoolId: String!, $schoolMemberId: String!) {
    removeSchoolMembers(id: $schoolId, schoolMemberIds: [$schoolMemberId]) {
      __typename
      id
      schoolMembers {
        id
        __typename
      }
    }
  }
`;

const youMustBeLoggedInMessage = 'You must be logged in to view this page.';
const schoolModelName = 'School';
const renameSchoolActionLabel = 'Rename School';
const leaveSchoolActionLabel = 'Leave School';

const RenameSchoolDialog = ({
  initialSchoolName,
  schoolId,
  handleClose,
}: {
  initialSchoolName: string;
  schoolId: string;
  handleClose: () => void;
}) => {
  const [renameSchool, { loading, error }] = useMutation<
    RenameSchool,
    RenameSchoolVariables
  >(RENAME_SCHOOL, {
    onError: (err) => logException(err),
    onCompleted: () => handleClose(),
  });

  return (
    <InputDialog
      active
      formId="rename-school-form"
      handleClose={handleClose}
      title="Rename School"
      initialValue={initialSchoolName}
      onConfirm={(name) => renameSchool({ variables: { schoolId, name } })}
      isLoading={loading}
      errorMessage={error?.message}
      inputProps={{ ariaLabel: 'Rename Input' }}
      confirmAction={{ label: 'Rename' }}
    />
  );
};

const LeaveSchoolDialog = ({
  schoolId,
  schoolMemberId,
  handleClose,
}: {
  schoolId: string;
  schoolMemberId: string;
  handleClose: () => void;
}) => {
  const navigate = useNavigate();

  const [leaveSchool, { loading, error }] = useMutation<
    LeaveSchool,
    LeaveSchoolVariables
  >(LEAVE_SCHOOL, {
    onError: (err) => logException(err),
    onCompleted: () => navigate(`/${dashboardPath}`),
    variables: {
      schoolId,
      schoolMemberId,
    },
  });

  return (
    <Dialog
      active
      handleClose={handleClose}
      title="Leave School"
      description="Are you sure you want to leave this school? You will lose access to your classes, assignments and premium content."
      isLoading={loading}
      confirmAction={{
        label: 'Leave',
        onClick: () => leaveSchool(),
        disabled: loading,
      }}
      size="medium"
    >
      {loading && (
        <div>
          <PtProgress className={styles.leaveDialogLoading} />
        </div>
      )}

      {!!error && (
        <span className={styles.leaveDialogErrorMessage}>
          {errorDialogMessage(`leaving: ${error.message}`)}
        </span>
      )}
    </Dialog>
  );
};

const SchoolPageBanner = ({
  schoolId,
  schoolName,
  userSchoolMember,
  alertMessages,
}: {
  schoolId: string;
  schoolName: string;
  userSchoolMember?: {
    role: SCHOOL_MEMBER_ROLE;
    id: string;
  } | null;
  alertMessages: string[];
}) => {
  const [isOptionsMenuOpened, setIsOptionsMenuOpened] = useState(false);
  const [isRenameDialogActive, setIsRenameDialogActive] = useState(false);
  const [isLeaveDialogActive, setIsLeaveDialogActive] = useState(false);

  const optionsMenuItems: MenuItem[] = [];
  if (userSchoolMember?.role === SCHOOL_MEMBER_ROLE.admin) {
    optionsMenuItems.push({
      onClick: () => setIsRenameDialogActive(true),
      content: renameSchoolActionLabel,
      label: renameSchoolActionLabel,
      type: 'button',
    });
  }
  if (
    userSchoolMember &&
    userSchoolMember.role !== SCHOOL_MEMBER_ROLE.student
  ) {
    optionsMenuItems.push({
      onClick: () => setIsLeaveDialogActive(true),
      content: leaveSchoolActionLabel,
      label: leaveSchoolActionLabel,
      type: 'button',
    });
  }

  return (
    <div className={styles.bannerContainer}>
      <div className={styles.banner}>
        <h1>{schoolName}</h1>

        {optionsMenuItems.length > 0 && (
          <DropdownMenu
            button={
              <MenuButton
                onClick={(event) => {
                  event.stopPropagation();
                  setIsOptionsMenuOpened((opened) => !opened);
                }}
                menuIsOpen={isOptionsMenuOpened}
                aria-label="Options Menu"
              />
            }
            isMenuOpened={isOptionsMenuOpened}
            forceCloseMenu={() => setIsOptionsMenuOpened(false)}
            menuItems={optionsMenuItems}
            className={styles.optionsMenu}
          />
        )}

        {isRenameDialogActive && (
          <RenameSchoolDialog
            schoolId={schoolId}
            initialSchoolName={schoolName}
            handleClose={() => setIsRenameDialogActive(false)}
          />
        )}
        {isLeaveDialogActive && userSchoolMember && (
          <LeaveSchoolDialog
            schoolId={schoolId}
            schoolMemberId={userSchoolMember.id}
            handleClose={() => setIsLeaveDialogActive(false)}
          />
        )}
      </div>
      <div className={styles.alertsContainer}>
        {alertMessages.map((message) => (
          <Tag
            key={message}
            className={styles.alertTag}
            leftAction={<WarningIcon className={styles.alertIcon} />}
            label={message}
          />
        ))}
      </div>
    </div>
  );
};

const SchoolPage = () => {
  const { myAccount } = useAuth();
  const { schoolId = '' } = useParams();

  const { data, error, loading } = useQuery<
    GetSchoolPage,
    GetSchoolPageVariables
  >(GET_SCHOOL_PAGE, {
    variables: { schoolId },
    onError: (err) => logException(err),
  });

  const { school } = data || {};

  const schoolNotFound = errorHasStatusCode(error, 404);

  const alertMessages = useMemo(() => {
    if (!school) return [];

    if (isOverAllocated(school)) {
      return [schoolOverAllocatedWarning];
    }
    if (hasNoLicenses(school)) {
      return [schoolNoLicensesWarning];
    }
    if (hasUnpaidLicenses(school)) {
      return [schoolUnpaidLicensesWarning];
    }
    if (
      school.schoolMembers.some((member) => member.missingContentAccess.length)
    ) {
      return ['Students need content access for some assignments'];
    }
    return [];
  }, [school]);

  const rosterMembers = useMemo(
    () =>
      (school?.schoolMembers || [])
        .filter(
          (
            schoolMember
          ): schoolMember is Omit<typeof schoolMember, 'account'> & {
            account: GetSchoolPage_school_schoolMembers_account;
          } => !!schoolMember.account
        )
        .map((schoolMember) => ({
          ...schoolMember.account,
          id: schoolMember.id,
          role: schoolMember.role,
          passwordManaged: schoolMember.passwordManaged,
          isCurrentUser:
            !!myAccount && myAccount.id === schoolMember.account?.id,
          classes: school!.schoolClasses.filter((schoolClass) =>
            schoolClass.schoolClassMembers.some(
              (schoolClassMember) =>
                schoolClassMember.schoolMember?.id === schoolMember.id
            )
          ),
          contentAccess: school!.schoolContentAccess
            // user must have been granted access to non-building content
            .filter(
              (schoolContentAccess) =>
                schoolContentAccess.schoolMembers.some(
                  (contentAccessSchoolMember) =>
                    contentAccessSchoolMember.id === schoolMember.id
                ) || schoolContentAccess.numBuildingLicenses > 0
            )
            .filter(
              (schoolContentAccess) =>
                schoolContentAccess.realm || schoolContentAccess.course
            )
            .sort((a, b) => {
              // if building licenses are equal, move realm to front over course
              if (a.numBuildingLicenses === b.numBuildingLicenses) {
                if (a.realm && b.course) return -1;
                if (a.course && b.realm) return 1;
                return 0;
              }
              // building content accesses should be at the end
              return a.numBuildingLicenses > b.numBuildingLicenses ? 1 : -1;
            }),
          missingContentAccess: schoolMember.missingContentAccess.map(
            (mca) =>
              // the member ones could be dummy objects if it's not in the schoolContentAccess
              school!.schoolContentAccess.find(({ id }) => id === mca.id) || mca
          ),
        })),
    [school, myAccount]
  );

  const classes = useMemo(
    () =>
      (school?.schoolClasses || []).map((schoolClass) => ({
        ...schoolClass,
        teachers: schoolClass.schoolClassMembers
          .filter(
            (schoolClassMember) =>
              schoolClassMember.role === SCHOOL_CLASS_MEMBER_ROLE.teacher &&
              schoolClassMember.schoolMember?.account
          )
          .map((schoolClassMember) => {
            const name = getAccountName(
              schoolClassMember.schoolMember!.account!
            );
            if (
              myAccount &&
              myAccount.id === schoolClassMember.schoolMember?.account?.id
            ) {
              return `You (${name})`;
            }
            return name;
          }),
        numStudents: schoolClass.schoolClassMembers.filter(
          (schoolClassMember) =>
            schoolClassMember.role === SCHOOL_CLASS_MEMBER_ROLE.student
        ).length,
        numAssignments: schoolClass.assignments.length,
      })),
    [myAccount, school]
  );

  useTrackEvent('View School', { schoolId }, [schoolId]);

  if (!myAccount || (!school && loading)) {
    return (
      <Placeholder
        isLoading={loading}
        message={
          loading
            ? placeholderLoading(schoolModelName.toLowerCase())
            : youMustBeLoggedInMessage
        }
      />
    );
  }

  const userSchoolMember = school?.schoolMembers.find(
    (schoolMember) => schoolMember.account?.id === myAccount.id
  );

  if (schoolNotFound || !school) {
    return <FourOhFour resourceName={schoolModelName} />;
  }

  const userIsSchoolAdmin = userSchoolMember?.role === SCHOOL_MEMBER_ROLE.admin;
  const userIsSchoolTeacher =
    userSchoolMember?.role === SCHOOL_MEMBER_ROLE.teacher;

  const isSchoolEditor = userIsSchoolAdmin || userIsSchoolTeacher;

  return (
    <TabsPageLayout
      pageName={school.name}
      stickyBarSlot={
        <Breadcrumbs
          crumbs={[
            { label: 'Dashboard', to: `/${dashboardPath}` },
            { label: school.name, to: `/schools/${school.id}` },
          ]}
        />
      }
      bannerSlot={
        <SchoolPageBanner
          schoolId={school.id}
          schoolName={school.name}
          userSchoolMember={userSchoolMember}
          alertMessages={alertMessages}
        />
      }
      initialActiveTab="roster"
      tabBarSlot={
        isSchoolEditor && (
          <>
            <Tabs.Tab name="roster">ROSTER</Tabs.Tab>
            <Tabs.Tab name="classes">CLASSES</Tabs.Tab>
            <Tabs.Tab name="licenses">LICENSES</Tabs.Tab>
          </>
        )
      }
      tabsSlot={
        isSchoolEditor ? (
          <>
            <Tabs.Content name="roster">
              <SchoolRoster
                schoolId={school.id}
                members={rosterMembers}
                schoolContentAccess={school.schoolContentAccess}
                userIsSchoolAdmin={userIsSchoolAdmin}
                isSchoolEditor={isSchoolEditor}
                className={styles.roster}
              />
            </Tabs.Content>

            <Tabs.Content name="classes">
              <SchoolClasses
                school={school}
                isSchoolEditor={isSchoolEditor}
                classes={classes}
                className={styles.classes}
              />
            </Tabs.Content>

            <Tabs.Content name="licenses">
              <SchoolLicenses
                schoolId={school.id}
                licenses={school.licenses || []}
                schoolContentAccess={school.schoolContentAccess}
                userIsSchoolAdmin={userIsSchoolAdmin}
              />
            </Tabs.Content>
          </>
        ) : (
          <Placeholder
            short
            message="You are a student in this school and do not have access to school information."
            className={styles.studentPlaceholder}
          />
        )
      }
    />
  );
};

export default memo(SchoolPage);
