import React, { PropsWithChildren, useState } from 'react';
import Tab from './Tab';
import TabContent from './TabContent';
import TabBar from './TabBar';

const TabsContext = React.createContext<
  { activeTab: string; setActiveTab: (tabName: string) => void } | undefined
>(undefined);

export function useTabsContext(componentName: string) {
  const context = React.useContext(TabsContext);

  if (!context) {
    throw new Error(`${componentName} must be used within a Tabs component`);
  }

  return context;
}

export type Props = PropsWithChildren<{
  initialActiveTab: string;
  onTabChange?: (tabName: string) => void;
  className?: string;
  'data-testid'?: string;
}>;

const Tabs = ({
  children,
  initialActiveTab,
  onTabChange = () => {},
  className,
  'data-testid': dataTestId,
}: Props) => {
  const [contextValue, setContextValue] = useState({
    activeTab: initialActiveTab,
    setActiveTab: (tabName: string) => {
      onTabChange(tabName);
      setContextValue((prevContextValue) => ({
        ...prevContextValue,
        activeTab: tabName,
      }));
    },
  });

  return (
    <TabsContext.Provider value={contextValue}>
      <div data-testid={dataTestId} className={className}>
        {children}
      </div>
    </TabsContext.Provider>
  );
};

Tabs.Tab = Tab;
Tabs.Content = TabContent;
Tabs.TabBar = TabBar;

export default Tabs;
