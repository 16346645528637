import { useEffect } from 'react';

import track from '../utils/track';

export function useTrackEvent(eventName: string, deps: any[]): void;
export function useTrackEvent(
  eventName: string,
  eventData: Record<string, string>,
  deps: any[]
): void;
export function useTrackEvent(
  eventName: string,
  secondArg: Record<string, string> | any[],
  thirdArg?: any[]
) {
  const deps = Array.isArray(secondArg) ? secondArg : thirdArg;
  const eventData = Array.isArray(secondArg) ? null : secondArg;

  useEffect(() => {
    if (eventData) {
      track(eventName, eventData);
    } else {
      track(eventName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
